@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input, select {
  vertical-align: middle;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

body {
  font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "MS Ｐ明朝", "MS PMincho", "MS 明朝", serif;
}

input,
select,
button,
textarea {
  box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=number],
input[type=submit],
input[type=button],
input[type=reset],
textarea,
button,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

input[type=submit],
input[type=button],
input[type=reset],
button,
label[for] {
  cursor: pointer;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

img {
  vertical-align: bottom;
}

html, body {
  height: 100%;
}

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.header .header_inner {
  display: flex;
  align-items: center;
  padding: 30px 60px 0 60px;
}
@media screen and (max-width: 640px) {
  .header .header_inner {
    padding: 30px 25px 0 0;
  }
}
.header .header_inner .logo img {
  max-width: 287px;
  height: auto;
}
@media screen and (max-width: 640px) {
  .header .header_inner .logo {
    display: none;
  }
}
.header .header_inner .drawer {
  margin-left: auto;
  cursor: pointer;
}
.header .header_inner .drawer img {
  width: 32px;
  height: auto;
}

.global_nav {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #231815;
  transition: visibility 0.25s, opacity 0.25s;
}
.global_nav.open {
  visibility: visible;
  opacity: 1;
}
.global_nav .close {
  position: absolute;
  top: 30px;
  right: 25px;
  cursor: pointer;
}
.global_nav .close img {
  width: 100%;
  max-width: 32px;
  height: auto;
}
.global_nav .global_nav_inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 375px;
  margin: 0 auto;
}
.global_nav .global_nav_inner ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0 50px;
}
.global_nav .global_nav_inner ul li {
  font-size: 1.875rem;
  line-height: 1.8;
  letter-spacing: 100;
}
.global_nav .global_nav_inner ul li + li {
  margin-top: 10px;
}
.global_nav .global_nav_inner ul li a {
  color: #fff;
  text-decoration: none;
}
.global_nav .global_nav_inner .global_nav_footer {
  margin-top: auto;
  text-align: center;
  padding-bottom: 20px;
}
.global_nav .global_nav_inner .global_nav_footer img {
  width: 100%;
  max-width: 105px;
  height: auto;
}
.global_nav .global_nav_inner .global_nav_footer p {
  margin-top: 30px;
  font-size: 0.75rem;
  color: #fff;
}

.footer {
  margin-top: 150px;
}
@media screen and (max-width: 640px) {
  .footer {
    margin-top: 90px;
  }
}
.footer .footer_head {
  text-align: center;
}
.footer .footer_head img {
  width: 100%;
  max-width: 105px;
  height: auto;
}
.footer .footer_bottom {
  margin-top: 30px;
  padding: 35px 0 15px;
  background-color: #231815;
}
.footer .footer_bottom .copy {
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
}

.retrun_btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.retrun_btn a {
  display: grid;
  place-content: center;
  width: 100%;
  max-width: 350px;
  height: 55px;
  font-size: 0.9375rem;
  color: #333;
  text-decoration: none;
  border: solid 1px #ccc;
}

.entry_btn {
  display: flex;
  justify-content: center;
  margin: 50px 0 100px;
}
.entry_btn a {
  display: grid;
  place-content: center;
  width: 100%;
  max-width: 450px;
  height: 55px;
  font-size: 0.9375rem;
  color: #fff;
  text-decoration: none;
  background-color: #333;
}

.video {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
}
.video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
@media screen and (max-width: 640px) {
  .video video.video_pc {
    display: none;
  }
}
.video video.video_sp {
  display: none;
}
@media screen and (max-width: 640px) {
  .video video.video_sp {
    display: block;
  }
}

.firstview_inner {
  margin-top: -100vh;
}
.firstview_inner .firstview_logo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.firstview_inner .firstview_logo .scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 15px;
  color: #fff;
}
.firstview_inner .firstview_logo .scroll::after {
  content: "";
  width: 1px;
  height: 30px;
  background-color: #fff;
  margin-top: 5px;
}
.firstview_inner .firstview_message {
  width: 100%;
  text-align: center;
  padding: 170px 0;
  background: linear-gradient(rgba(0, 0, 0, 0), 15%, rgba(0, 0, 0, 0.4));
}
.firstview_inner .firstview_message h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.1875rem;
  font-weight: normal;
  color: #DAE000;
  margin-bottom: 60px;
}
.firstview_inner .firstview_message h2::after {
  content: "";
  display: block;
  width: 65px;
  height: 1px;
  background-color: #DAE000;
  margin-top: 15px;
}
.firstview_inner .firstview_message p {
  font-size: 1.1875rem;
  line-height: 2.6;
  color: #fff;
}

.section_title {
  margin-bottom: 40px;
}
.section_title h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.1875rem;
  font-weight: normal;
  color: #231815;
  letter-spacing: 0.1em;
}
.section_title h2::after {
  content: "";
  display: block;
  width: 65px;
  height: 1px;
  background-color: #231815;
  margin-top: 15px;
}
.section_title h2 + p {
  margin-top: 25px;
}
.section_title p {
  font-size: 1.1875rem;
  text-align: center;
  letter-spacing: 0.1em;
}

.mission {
  max-width: 910px;
  margin: 0 auto;
  padding: 75px 35px 0;
}
.mission .our_mission {
  font-size: 1.0625rem;
  line-height: 1.7;
}

.business {
  overflow: hidden;
  padding: 75px 0 0;
}

.business_list {
  max-width: 840px;
  margin: 0 auto;
}
.business_list li {
  display: flex;
  list-style: none;
}
.business_list li + li {
  margin-top: 35px;
}
.business_list li a {
  width: 69.7674%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 300/168;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1.375rem;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0.1em;
}
.business_list li:nth-child(even) {
  justify-content: flex-end;
}

.people .people_inner {
  padding: 100px 20px 0;
}

.people_list {
  max-width: 840px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px 20px;
}
@media screen and (max-width: 640px) {
  .people_list {
    grid-template-columns: repeat(2, 1fr);
  }
}
.people_list li {
  list-style: none;
}
.people_list li a {
  text-decoration: none;
}
.people_list li a img {
  width: 100%;
  height: auto;
}
.people_list li a h3 {
  margin-top: 15px;
  font-size: 0.9375rem;
  color: #231815;
  line-height: 1.6;
  letter-spacing: 0.1em;
}
.people_list li a h3 span {
  display: block;
  font-size: 0.6875rem;
  color: #231815;
  line-height: 1.54;
}

.workstyle .workstyle_inner {
  max-width: 840px;
  margin: 0 auto;
  padding: 100px 0 0;
}
.workstyle .workstyle_inner .workstyle_img img {
  width: 100%;
  height: auto;
}

.workstyle_contetns .workstyle_item {
  margin-top: 45px;
  padding: 0 35px;
}
.workstyle_contetns .workstyle_item img {
  width: 100%;
  height: auto;
}
.workstyle_contetns .workstyle_item img + img {
  margin-top: 12px;
}
.workstyle_contetns .workstyle_item h3 {
  font-size: 1.625rem;
  margin: 30px 0 20px 0;
  text-align: center;
}
.workstyle_contetns .workstyle_item p {
  font-size: 0.9375rem;
  line-height: 1.7;
}

.recruit .recruit_inner {
  max-width: 860px;
  margin: 0 auto;
  padding: 100px 20px 0;
}
.recruit .recruit_inner .preparation {
  text-align: center;
}

.recruit_item + .recruit_item {
  margin-top: 80px;
}
.recruit_item h3 {
  display: flex;
  align-items: flex-end;
  font-size: 1.625rem;
  letter-spacing: 0.1em;
  margin-bottom: 25px;
}
.recruit_item h3::before {
  content: "";
  display: block;
  width: 27px;
  height: 26px;
  background-image: url(../img/icon_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 15px;
}
.recruit_item p {
  font-size: 0.9375rem;
  line-height: 1.54;
}
.recruit_item .btn_more {
  margin-top: 25px;
}
.recruit_item .btn_more a {
  letter-spacing: 0.1em;
  background-image: url(../img/icon_more.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 17px 4px;
  text-decoration: none;
  color: #231815;
  background-color: #DAE000;
  padding: 5px 30px 5px 15px;
}

.partners {
  margin-top: 90px;
  padding: 0 0 60px;
  background-color: #EFEEE8;
}
.partners .partners_inner {
  text-align: center;
}
.partners .partners_inner .partners_img {
  margin-bottom: 55px;
}
.partners .partners_inner .partners_img img {
  width: 100%;
  max-width: 840px;
  height: auto;
}
.partners .preparation {
  text-align: center;
}

.partners_item {
  max-width: 820px;
  margin: 0 auto;
}
.partners_item ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.partners_item ul li {
  width: calc(33.333% - 20px);
  list-style: none;
  text-align: center;
}
.partners_item ul li img {
  max-width: 100%;
  max-height: auto;
  object-fit: contain;
}

.company {
  margin-top: 90px;
  padding: 0 35px;
}

.company_table {
  width: 100%;
  max-width: 395px;
  margin: 0 auto;
}
.company_table tr {
  text-align: left;
}
.company_table tr th, .company_table tr td {
  padding: 10px 0;
  line-height: 1.7;
}
.company_table tr th {
  width: 200px;
}
.company_table tr td {
  font-size: 1.125rem;
}
.company_table tr td a {
  display: flex;
  align-items: center;
  color: #231815;
  text-decoration: none;
}
.company_table tr td a span {
  border-bottom: solid 1px #231815;
}
.company_table tr td a.map::before {
  content: "";
  display: block;
  width: 12px;
  height: 18px;
  background-image: url(../img/icon_googlemap.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 5px;
}

.page {
  max-width: 780px;
  padding: 200px 35px 0;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .page {
    padding: 70px 35px 0;
  }
}

.create img {
  max-width: 100%;
  height: auto;
}
.create h3 {
  font-size: 1.75rem;
  letter-spacing: 0.1em;
  margin: 45px 0 50px;
  text-align: center;
}
.create p {
  font-size: 0.9375rem;
  line-height: 1.75;
  letter-spacing: 0.1em;
}
.create h4 {
  display: flex;
  font-size: 0.9375rem;
  font-weight: normal;
  letter-spacing: 0.1em;
  margin: 50px 0 15px;
}
.create h4::before {
  content: "−";
  margin-right: 0.8em;
}
.create h4::after {
  content: "−";
  margin-left: 0.8em;
}
.create .logos {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media screen and (max-width: 640px) {
  .create .logos {
    grid-template-columns: auto;
  }
}
.create .logos li {
  list-style: none;
}
.create .logos li img {
  width: 100%;
  height: auto;
}

.people_single .main_img {
  margin-bottom: 20px;
}
.people_single .main_img img {
  width: 100%;
  height: auto;
}
.people_single .people_name {
  margin-bottom: 70px;
}
.people_single .people_name p {
  margin-bottom: 15px;
}
.people_single .people_name p small {
  font-size: 1rem;
  letter-spacing: 0.1em;
}
.people_single .people_name h3 {
  display: flex;
  align-items: center;
  font-size: 1.375rem;
  letter-spacing: 0.1em;
}
.people_single .people_name h3 span {
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  margin-left: 1em;
}
.people_single .people_single_contents {
  text-align: center;
}
.people_single .people_single_contents img {
  width: 100%;
  max-width: 515px;
  height: auto;
}
.people_single .people_single_contents h1, .people_single .people_single_contents h2, .people_single .people_single_contents h3, .people_single .people_single_contents h4, .people_single .people_single_contents h5, .people_single .people_single_contents h6 {
  font-size: 1.3125rem;
  letter-spacing: 0.1em;
  line-height: 1.8;
  margin-top: 40px;
  text-align: left;
}
.people_single .people_single_contents p {
  font-size: 0.9375rem;
  line-height: 1.7;
  margin-top: 30px;
  text-align: left;
}
.people_single .people_single_contents p + img {
  margin-top: 65px;
}

.recruit_single .recruit_title {
  margin-bottom: 60px;
}
.recruit_single .recruit_title h3 {
  display: flex;
  align-items: flex-end;
  font-size: 1.625rem;
  letter-spacing: 0.1em;
  margin-bottom: 25px;
}
.recruit_single .recruit_title h3::before {
  content: "";
  display: block;
  width: 27px;
  height: 26px;
  background-image: url(../img/icon_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 15px;
}
.recruit_single h4 {
  font-size: 1.0625rem;
  text-align: center;
  background-color: #DADF00;
  padding: 5px 20px;
}
.recruit_single h4 + p {
  margin-top: 15px;
}
.recruit_single p {
  font-size: 0.9375rem;
  line-height: 1.7;
}
.recruit_single p + h4 {
  margin-top: 40px;
}

.contact .contact_inner {
  max-width: 720px;
  padding: 200px 35px 0;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .contact .contact_inner {
    padding: 70px 35px 0;
  }
}
.contact .contact_text {
  font-size: 1.0625rem;
  line-height: 1.7;
  margin-bottom: 50px;
}
.contact form .input_label {
  display: flex;
  align-items: center;
  background-color: #DADF00;
  font-size: 1.0625rem;
  padding: 5px 15px;
}
.contact form .input_label + input[type=text], .contact form .input_label + input[type=email], .contact form .input_label + input[type=tel], .contact form .input_label + textarea {
  margin-top: 15px;
}
.contact form .input_label span {
  display: block;
  padding: 2px 5px;
  background-color: #E50012;
  font-size: 0.8125rem;
  color: #fff;
  border-radius: 20px;
  margin-left: auto;
}
.contact form input[type=text],
.contact form input[type=email],
.contact form input[type=tel],
.contact form textarea {
  width: 100%;
  border: solid 1px #DBDCDC;
  font-size: 0.9375rem;
  padding: 10px 15px;
}
.contact form input[type=text] + .errtxt,
.contact form input[type=email] + .errtxt,
.contact form input[type=tel] + .errtxt,
.contact form textarea + .errtxt {
  margin-top: 10px;
}
.contact form input[type=text] + .input_label,
.contact form input[type=email] + .input_label,
.contact form input[type=tel] + .input_label,
.contact form textarea + .input_label {
  margin-top: 40px;
}
.contact form p + p {
  margin-top: 40px;
}
.contact form input[type=submit] {
  display: block;
  width: 175px;
  height: 40px;
  margin: 45px auto 0;
  font-size: 1.1875rem;
  color: #fff;
  border-radius: 30px;
  background-color: #E50012;
}
.contact form .errtxt, .contact form .wpcf7-not-valid-tip {
  margin-top: 10px;
  color: #E50012;
  font-size: 0.8125rem;
}
.contact form .errtxt + .input_label, .contact form .wpcf7-not-valid-tip + .input_label {
  margin-top: 40px;
}
.contact form .wpcf7-response-output {
  padding: 0.8em 1em !important;
}
.contact form .radio_area {
  margin-top: 15px;
}
.contact form .radio_area + .errtxt {
  margin-top: 10px;
}
.contact form .radio_area + p {
  margin-top: 40px;
}
.contact .contact_note {
  margin-top: 40px;
}
.contact .contact_note p {
  font-size: 0.625rem;
  line-height: 1.7;
}