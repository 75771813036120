@use '../base/vars' as *;

.firstview{
    // width: 100vw;
    // height: 100vh;
    // overflow: hidden;
}

// .video{
//     position: absolute;
//     width: 100vw;
//     height: 100vh;
//     overflow: hidden;
    
//     video{
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100vw;
//         height: auto;
        
//         @include media_query(){
//             width: auto;
//             height: 100vh;
//         }
//     }
// }

.video{
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    
    video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        
        // @include media_query(){
        //     width: auto;
        //     height: 100vh;
        //     transform: translate(-33.6%, 0%);
        // }
        
        &.video_pc{
            @include media_query(){
                display: none;
            }
        }
        
        &.video_sp{
            display: none;
            
            @include media_query(){
                display: block
            }
        }
    }
}

.firstview_inner{
    margin-top: -100vh;
    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100vw;
    // height: 100vh;
    // overflow: scroll;
    // z-index: 9;
    
    .firstview_logo{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        
        .scroll{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            bottom: 15px;
            color: #fff;
            
            &::after{
                content: "";
                width: 1px;
                height: 30px;
                background-color: #fff;
                margin-top: 5px;
            }
        }
    }
    
    .firstview_message{
        width: 100%;
        text-align: center;
        padding: 170px 0;
        background: linear-gradient(rgba(0,0,0,0), 15%, rgba(0,0,0,0.4));
        
        h2{
            display: flex;
            flex-direction: column;
            align-items: center;
            @include rem(35);
            font-weight: normal;
            color: #DAE000;
            margin-bottom: 60px;
            
            &::after{
                content: "";
                display: block;
                width: 65px;
                height: 1px;
                background-color: #DAE000;
                margin-top: 15px;
            }
        }
        
        p{
            @include rem(19);
            line-height: 2.6;
            color: #fff;
        }
    }
}

.section_title{
    margin-bottom: 40px;
    
    h2{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include rem(35);
        font-weight: normal;
        color: $font-color;
        letter-spacing: .1em;
        
        &::after{
            content: "";
            display: block;
            width: 65px;
            height: 1px;
            background-color: $font-color;
            margin-top: 15px;
        }
        
        & + p{
            margin-top: 25px;
        }
    }
    
    p{
        @include rem(19);
        text-align: center;
        letter-spacing: .1em;
    }
}




.mission{
    max-width: 910px;
    margin: 0 auto;
    padding: 75px 35px 0;
    
    .our_mission{
        @include rem(17);
        line-height: 1.7;
    }
}





.business{
    overflow: hidden;
    padding: 75px 0 0;
}




.business_list{
    max-width: 840px;
    margin: 0 auto;
    
    li{
        display: flex;
        list-style: none;
        
        & + li{
            margin-top: 35px;
        }
        
        a{
            width: 69.7674%;
            max-width: 450px;
            display: flex;
            align-items: center;
            justify-content: center;
            aspect-ratio: 300 / 168;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            @include rem(22);
            color: #fff;
            text-decoration: none;
            letter-spacing: .1em;
        }
        
        &:nth-child(even){
            justify-content: flex-end;
        }
    }
}




.people{
    .people_inner{
        padding: 100px 20px 0;
    }
}

.people_list{
    max-width: 840px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px 20px;
    
    @include media_query(){
        grid-template-columns: repeat(2, 1fr);
    }
    
    li{
        list-style: none;
        
        a{
            text-decoration: none;
            
            img{
                width: 100%;
                height: auto;
            }
            
            h3{
                margin-top: 15px;
                @include rem(15);
                color: $font-color;
                line-height: 1.6;
                letter-spacing: .1em;
                
                span{
                    display: block;
                    @include rem(11);
                    color: $font-color;
                    line-height: 1.54;
                }
            }
        }
    }
}





.workstyle{
    .workstyle_inner{
        max-width: 840px;
        margin: 0 auto;
        padding: 100px 0 0;
        
        .workstyle_img{
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}

.workstyle_contetns{
    .workstyle_item{
        margin-top: 45px;
        padding: 0 35px;
        
        img{
            width: 100%;
            height: auto;
            
            & + img{
                margin-top: 12px;
            }
        }
        
        h3{
            @include rem(26);
            margin: 30px 0 20px 0;
            text-align: center;
        }
        
        p{
            @include rem(15);
            line-height: 1.7;
        }
    }
}




.recruit{
    .recruit_inner{
        max-width: 860px;
        margin: 0 auto;
        padding: 100px 20px 0;
        
        .preparation{
            text-align: center;
        }
    }
}

.recruit_item{
    & + .recruit_item{
        margin-top: 80px;
    }
    
    h3{
        display: flex;
        align-items: flex-end;
        @include rem(26);
        letter-spacing: .1em;
        margin-bottom: 25px;
        
        &::before{
            content: "";
            display: block;
            width: 27px;
            height: 26px;
            background-image: url(../img/icon_logo.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-right: 15px;
        }
    }
    
    p{
        @include rem(15);
        line-height: 1.54;
    }
    
    .btn_more{
        margin-top: 25px;
        
        a{
            letter-spacing: .1em;
            background-image: url(../img/icon_more.png);
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 17px 4px;
            text-decoration: none;
            color: $font-color;
            background-color: #DAE000;
            padding: 5px 30px 5px 15px;
        }
    }
}





.partners{
    margin-top: 90px;
    padding: 0 0 60px;
    background-color: #EFEEE8;
    
    .partners_inner{
        text-align: center;
        .partners_img{
            margin-bottom: 55px;
            
            img{
                width: 100%;
                max-width: 840px;
                height: auto;
            }
        }
    }
    
    .preparation{
        text-align: center;
    }
}

.partners_item{
    max-width: 820px;
    margin: 0 auto;
    
    ul{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        
        @include media_query(){
            
        }
        
        li{
            width: calc(33.333% - 20px);
            list-style: none;
            text-align: center;
            
            img{
                max-width: 100%;
                max-height: auto;
                object-fit: contain;
            }
        }
    }
}





.company{
    margin-top: 90px;
    padding: 0 35px;
}

.company_table{
    width: 100%;
    max-width: 395px;
    margin: 0 auto;
    
    tr{
        text-align: left;
        
        th,td{
            padding: 10px 0;
            line-height: 1.7;
        }
        
        th{
            width: 200px;
            //padding-right: 50px;
        }
        
        td{
            @include rem(18);
            
            a{
                display: flex;
                align-items: center;
                color: $font-color;
                text-decoration: none;
                
                span{
                    border-bottom: solid 1px $font-color;
                }
                
                &.map{
                    &::before{
                        content: "";
                        display: block;
                        width: 12px;
                        height: 18px;
                        background-image: url(../img/icon_googlemap.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}