@use '../base/vars' as *;

.page{
    max-width: 780px;
    padding: 200px 35px 0;
    margin: 0 auto;
    
    @include media_query(){
        padding: 70px 35px 0;
    }
}





.create{
    img{
        max-width: 100%;
        height: auto;
    }
    
    h3{
        @include rem(28);
        letter-spacing: .1em;
        margin: 45px 0 50px;
        text-align: center;
    }
    
    p{
        @include rem(15);
        line-height: 1.75;
        letter-spacing: .1em;
    }
    
    h4{
        display: flex;
        @include rem(15);
        font-weight: normal;
        letter-spacing: .1em;
        margin: 50px 0 15px;
        
        &::before{
            content: "−";
            margin-right: .8em;
        }
        
        &::after{
            content: "−";
            margin-left: .8em;
        }
    }
    
    .logos{
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        
        @include media_query(){
            grid-template-columns: auto;
        }
        
        li{
            list-style: none;
            
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}





.people_single{
    .people_single_inner{
        
    }
    
    .main_img{
        margin-bottom: 20px;
        
        img{
            width: 100%;
            height: auto;
        }
    }
    
    .people_name{
        margin-bottom: 70px;
        
        p{
            margin-bottom: 15px;
            small{
                @include rem(16);
                letter-spacing: .1em;
            }
        }
        
        h3{
            display: flex;
            align-items: center;
            @include rem(22);
            letter-spacing: .1em;
            
            span{
                @include rem(12);
                letter-spacing: .1em;
                margin-left: 1em;
            }
        }
    }
    
    
    .people_single_contents{
        text-align: center;
        
        img{
            width: 100%;
            max-width: 515px;
            height: auto;
        }
        
        h1,h2,h3,h4,h5,h6{
            @include rem(21);
            letter-spacing: .1em;
            line-height: 1.8;
            margin-top: 40px;
            text-align: left;
        }
        
        p{
            @include rem(15);
            line-height: 1.7;
            margin-top: 30px;
            text-align: left;
            
            & + img{
                margin-top: 65px;
            }
        }
    }
}










.recruit_single{
    .recruit_title{
        margin-bottom: 60px;
        
        h3{
            display: flex;
            align-items: flex-end;
            @include rem(26);
            letter-spacing: .1em;
            margin-bottom: 25px;
            
            &::before{
                content: "";
                display: block;
                width: 27px;
                height: 26px;
                background-image: url(../img/icon_logo.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                margin-right: 15px;
            }
        }
    }
    
    h4{
        @include rem(17);
        text-align: center;
        background-color: #DADF00;
        padding: 5px 20px;
        
        & + p{
            margin-top: 15px;
        }
    }
    
    p{
        @include rem(15);
        line-height: 1.7;
        
        & + h4{
            margin-top: 40px;
        }
    }
}




.contact{
    .contact_inner{
        max-width: 720px;
        padding: 200px 35px 0;
        margin: 0 auto;
        
        @include media_query(){
            padding: 70px 35px 0;
        }
    }
    
    .contact_text{
        @include rem(17);
        line-height: 1.7;
        margin-bottom: 50px;
    }
    
    form{
        .input_label{
            display: flex;
            align-items: center;
            background-color: #DADF00;
            @include rem(17);
            padding: 5px 15px;
            
            & + input[type=text],
            & + input[type=email],
            & + input[type=tel],
            & + textarea{
                margin-top: 15px;
            }
            
            span{
                display: block;
                padding: 2px 5px;
                background-color: #E50012;
                @include rem(13);
                color: #fff;
                border-radius: 20px;
                margin-left: auto;
            }
        }
        
        input[type=text],
        input[type=email],
        input[type=tel],
        textarea{
            width: 100%;
            border: solid 1px #DBDCDC;
            @include rem(15);
            padding: 10px 15px;
            
            & + .errtxt{
                margin-top: 10px;
            }
            
            & + .input_label{
                margin-top: 40px;
            }
        }
        
        p + p{
            margin-top: 40px;
        }
        
        input[type=submit]{
            display: block;
            width: 175px;
            height: 40px;
            margin: 45px auto 0;
            @include rem(19);
            color: #fff;
            border-radius: 30px;
            background-color: #E50012;
        }
        
        .errtxt, .wpcf7-not-valid-tip{
            margin-top: 10px;
            color: #E50012;
            @include rem(13);
            
            & + .input_label{
                margin-top: 40px;
            }
        }
        
        .wpcf7-response-output{
            padding: .8em 1em !important;
        }
        
        .radio_area{
            margin-top: 15px;
            
            & + .errtxt{
                margin-top: 10px;
            }
            
            & + p{
                margin-top: 40px;
            }
        }
    }
    
    .contact_note{
        margin-top: 40px;
        
        p{
            @include rem(10);
            line-height: 1.7;
        }
    }
}