@use '../base/vars' as *;

html,body{
    height: 100%;
}

.wrap{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main{
    flex: 1;
}






.header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    
    .header_inner{
        display: flex;
        align-items: center;
        padding: 30px 60px 0 60px;
        
        @include media_query(){
            padding: 30px 25px 0 0;
        }
        
        .logo{
            img{
                max-width: 287px;
                height: auto;
            }
            
            @include media_query(){
                display: none;
            }
        }
        
        .drawer{
            margin-left: auto;
            cursor: pointer;
            
            img{
                width: 32px;
                height: auto;
            }
        }
    }
}




.global_nav{
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: $font-color;
    
    transition: visibility .25s, opacity .25s;
    
    &.open{
        visibility: visible;
        opacity: 1;
    }
    
    .close{
        position: absolute;
        top: 30px;
        right: 25px;
        cursor: pointer;
        
        img{
            width: 100%;
            max-width: 32px;
            height: auto;
        }
    }
    
    .global_nav_inner{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 375px;
        margin: 0 auto;
        
        ul{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            padding: 0 50px;
            
            li{
                @include rem(30);
                line-height: 1.8;
                letter-spacing: 100;
                
                & + li{
                    margin-top: 10px;
                }
                
                a{
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
        
        .global_nav_footer{
            margin-top: auto;
            text-align: center;
            padding-bottom: 20px;
            
            img{
                width: 100%;
                max-width: 105px;
                height: auto;
            }
            
            p{
                margin-top: 30px;
                @include rem(12);
                color: #fff;
            }
        }
    }
}




.footer{
    margin-top: 150px;
    
    @include media_query(){
        margin-top: 90px;
    }
    
    .footer_head{
        text-align: center;
        
        img{
            width: 100%;
            max-width: 105px;
            height: auto;
        }
    }
    
    .footer_bottom{
        margin-top: 30px;
        padding: 35px 0 15px;
        background-color: $font-color;
        
        .copy{
            text-align: center;
            color: #fff;
            @include rem(12);
        }
    }
}




.retrun_btn{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    
    a{
        display: grid;
        place-content: center;
        width: 100%;
        max-width: 350px;
        height: 55px;
        @include rem(15);
        color: #333;
        text-decoration: none;
        border: solid 1px #ccc;
    }
}

.entry_btn{
    display: flex;
    justify-content: center;
    margin: 50px 0 100px;
    
    a{
        display: grid;
        place-content: center;
        width: 100%;
        max-width: 450px;
        height: 55px;
        @include rem(15);
        color: #fff;
        text-decoration: none;
        background-color: #333;
    }
}